import React, {Component} from 'react';
import styled from 'styled-components';
import {Formik, Field, Form, ErrorMessage} from "formik";

import resources from '../../resources';
import {encode} from '../../utils';
import {pixels} from '../../config/breakpoints';
const {copy} = resources;

const ContactFormWrapper = styled.div`
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0 40px 100px 0 rgba(0, 0, 0, 0.15);

  @media(min-width: ${pixels.mobile}) {
    padding: 50px 20px;
    max-width: 40%;
    /* Bg: */
    background: #FFFFFF;
    position: relative;
    box-sizing: border-box;
    top: -200px;
    left: 50px;
  }
  
  @media(min-width: ${pixels.tablet}) {
    padding: 80px 70px;
    max-width: 40%;
    /* Bg: */
    top: -200px;
    left: 150px;
  }
`

const Heading = styled.div`
  /* Contact form: */
  font-size: 30px;
  color: #0A0D10;
  margin-bottom: 50px;
`
const StyledForm = styled(Form)`
  display: contents;
`
const Input = styled(Field)`
  border: 0;
  outline: 0;
  background: transparent;
  border-bottom: 1px solid #C7CCDA;
  margin-bottom: 30px;
  font-size: 18px;
  color: #6A7581;

  &:hover, &:focus, &:active {
    border-bottom: 1px solid #6A7581;
    color: #212121;
  }
`

const TextArea = styled(Field)`
  border: 0;
  outline: 0;
  background: transparent;
  border-bottom: 1px solid #C7CCDA;
  margin-bottom: 30px;
  font-size: 18px;
  color: #6A7581;
  resize: vertical;
  max-height: 300px;
  min-height: 100px;

  &:hover, &:focus, &:active {
    border-bottom: 1px solid #6A7581;
    color: #212121;
  }

`

const Button = styled.button`
  cursor: pointer;
  padding: 20px;
  color: white;
  background-color: #31576e;
  font-size: 16px;
  border: none;
  border-radius: 5px;

  &:hover, &:focus {
    background-color: #555555
  }
`

class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      phone: '',
      subject: '',
      message: '',
    }
  }


  render() {
    return (
      <ContactFormWrapper>
        <Heading>{copy.contactPage.heading}</Heading>
        <Formik
          initialValues={{name: "", email: "", phone: "", subject: "", message: ""}}
          validate={(values) => {
            let errors = {};
            if (!values.email) {
              errors.email = 'Email is required';
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = 'Invalid email address';
            }
            if (!values.message) {
              errors.message = 'Message is Required'
            }
            return errors;
          }}
          onSubmit={async (values, {setSubmitting, resetForm}) => {
            fetch("/", {
              method: "POST",
              headers: {"Content-Type": "application/x-www-form-urlencoded"},
              body: encode({
                "form-name": "contact",
                ...values
              })
            })
              .then(() => {
                alert('Success');
                resetForm()
              })
              .catch(() => {
                alert('Error');
              })
              .finally(() => setSubmitting(false))
          }}
        >
          {({isSubmitting}) => (
            <StyledForm name="contact" data-netlify="true" netlify-honeypot="bot-field">
              <input type="hidden" name="form-name" value="contact"/>
              <p style={{visibility: 'hidden ', margin: 0}}>
                <label>Don’t fill this out if you’re human: <input name="bot-field"/></label>
              </p>
              <Input name="name" type="text" placeholder="First and Surname"/>
              <p style={{color: 'red', margin:0}}>
                <ErrorMessage name="email"/>
              </p>
              <Input name="email" type="email" placeholder="Email address"/>
              <Input name="phone" type="text" placeholder="Phone number"/>
              <Input name="subject" type="text" placeholder="Subject"/>
              <p style={{color: 'red', margin:0}}>
                <ErrorMessage name="message"/>
              </p>
              <TextArea name="message" type="text" placeholder="Message"/>

              <Button type="submit" disabled={isSubmitting}>{copy.contactPage.button}</Button>
            </StyledForm>
          )}
        </Formik>
      </ContactFormWrapper>
    )
  }
}

export default ContactForm;