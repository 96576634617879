import React from 'react';
import styled from 'styled-components';

import ContactForm from './contactForm';
import resources from '../../resources';
import image from '../../assets/img/contact.png';
import {pixels} from '../../config/breakpoints';

const { copy } = resources;

const ContactWrapper = styled.div`
  @media(min-width: ${pixels.tablet}) {
    
  }
`

const ContactInfo = styled.div`
  width: 30%;
  margin: 80px 50px;
  order: -1;
  @media(min-width: ${pixels.mobile}) {
    order: 1;
  }

`
const ContactArea = styled.div`
  display: flex;
  flex-direction: column; 
  @media(min-width: ${pixels.mobile}) {
    flex-direction: row;
    justify-content: space-between;
  }
`
const InfoHeading = styled.p`
  /* Visit us: */
  font-size: 16px;
  color: #A7AEC2;
  margin: 5px 0;
`

const InfoDescription = styled.div`
  /* 417 Wick lane Suite: */
  font-size: 16px;
  color: #0A0D10;
  line-height: 24px;
  margin: 0;
`

const Image = styled.div`
  background-image: url(${props => props.src});
  background-repeat: no-repeat;
  height: 20vh;
  background-size: cover;
  @media(min-width: ${pixels.tablet}) {
    height: 51vh;
    background-size: cover;
  }
`

const ContactPage = () => {
  return (
    <ContactWrapper>
      <Image src={image} id="contact" />
      <ContactArea>
        <ContactForm />
        <ContactInfo>
          {
            copy.contactPage.info.map((i, index) => <div key={`contact-info-${index}`} style={{ marginBottom: 30 }}>
              <InfoHeading>{i.heading}</InfoHeading>
              <InfoDescription dangerouslySetInnerHTML={{ __html: i.description }} />
            </div >
            )
          }
        </ContactInfo>
      </ContactArea>
    </ContactWrapper>
  )
}

export default ContactPage;

