import * as React from "react"
import Contact from '../component/contactPage';
import Layout from "../component/Layout/Layout";

const ContactPage = () => {
    return (
        <Layout>
            <Contact/>
        </Layout>
    )
}
export default ContactPage
